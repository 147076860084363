import { type NetworkId, NetworkIdByName } from '@kwenta/sdk/types'

import styled, { useTheme } from 'styled-components'

import Connector from 'containers/Connector'
import type { FC } from 'react'
import { useAppSelector } from 'state/hooks'
import { selectIsWatcherMode } from 'state/wallet/selectors'

type ConnectionDotProps = {
	className?: string
}

const ConnectionDot: FC<ConnectionDotProps> = (props) => {
	const { network, isWalletConnected } = Connector.useContainer()
	const isWatcherMode = useAppSelector(selectIsWatcherMode)
	const theme = useTheme()

	let background = theme.colors.noNetwork

	if (isWalletConnected && !isWatcherMode) {
		switch (network?.id as NetworkId) {
			case NetworkIdByName.mainnet:
			case NetworkIdByName['mainnet-ovm']:
			case NetworkIdByName['base-mainnet']:
				background = theme.colors.mainnet
				break
			case NetworkIdByName['sepolia-ovm']:
			case NetworkIdByName['base-sepolia']:
				background = theme.colors.testnet
		}
	}
	return <Dot {...props} background={background} />
}

const Dot = styled.span<{ background: string }>`
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: 100%;
	background-color: ${(props) => props.background};
	margin-right: 6px;
`

export default ConnectionDot
